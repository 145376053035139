<template>
  <a-modal
    title="联系人"
    :visible="visible"
    @ok="whenSubmit"
    @cancel="$emit('cancel')"
    :destroyOnClose="true"
    width="540px"
    :okText="'确定'"
  >
    <a-form-model
      class="formInfo"
      ref="addContactForm"
      layout="horizontal"
      :rules="rules"
      :model="addContactForm"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      labelAlign="right"
    >
      <a-form-model-item label="联系人名称" prop="name">
        <a-input
          type="text"
          placeholder="在此输入联系人名称"
          v-model="addContactForm.name"
          autocomplete="off"
          :disabled="readonly"
          style="width: 250px"
        />
      </a-form-model-item>
      <a-form-model-item label="联系方式" prop="contactInfo">
        <a-input
          type="text"
          placeholder="在此输入联系方式"
          v-model="addContactForm.contactInfo"
          autocomplete="off"
          :disabled="isDetail"
          style="width: 250px"
        />
      </a-form-model-item>
      <a-form-model-item label="所属客户" prop="customerId">
        <a-select
          show-search
          mode="default"
          notFoundContent="暂未有客户数据，请先在客户管理中新建客户!"
          placeholder="请选择所属客户"
          :key="addContactForm.customerId"
          :defaultValue="addContactForm.customerId"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :disabled="readonly"
          @select="whenSelectCustomer"
          @search="handleCustomerSearch"
          style="width: 250px"
          allowClear
        >
          <a-select-option
            v-for="customer in customersForSelect"
            :key="customer.id"
            :value="customer.id"
            :title="`${customer.name}`"
          >
            {{ `${customer.name}` }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="启用状态" prop="enableStatus" v-show="!isAdd">
        <a-select
          v-model="addContactForm.enableStatus"
          style="width: 250px"
          :disabled="isDetail"
        >
          <a-select-option
            v-for="item in this.$parent.arrEnableStatus"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { isTelephone } from "@/utils/tools";
import {
  createContactPerson,
  updateContactPerson,
  getListCustomer,
} from "/src/api/customer.js";
export default {
  name: "AddContactPerson",
  props: {
    visible: { type: Boolean, default: () => false },
    readonly: { type: Boolean, default: () => false },
    form: { type: Object, default: () => {} },
    isEdit: { type: Boolean, default: () => false },
    isAdd: { type: Boolean, default: () => false },
    isDetail: { type: Boolean, default: () => false },
  },
  created() {
    this.handleCustomerSearch("");
  },
  computed: {},
  data() {
    let isPhone = (rule, value, callback) => {
      isTelephone(rule, value, callback);
    };
    let namevalidator = (rule, value, cbfn) => {
      var _this = this;
      var name = _this.addContactForm.name;
      if (!value) cbfn("联系人名称不能为空");
      if (new TextEncoder().encode(name).length > 60)
        cbfn("名称不能超过20个字!");
      cbfn();
    };
    return {
      customersForSelect: [],
      addContactForm: {
        name: "",
        contactInfo: "",
        creationTime: "",
        enableStatus: 0,
        customerId: undefined,
      },
      rules: {
        name: [{ required: true, validator: namevalidator, trigger: "blur" }],
        contactInfo: [{ validator: isPhone, trigger: "blur", required: true }],
        customerId: [
          { required: true, message: "请选择所属公司", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    /** 保存事件 */
    whenSubmit() {
      if (this.readonly) return this.$emit("cancel");
      this.$refs.addContactForm.validate((valid) => {
        if (valid) {
          if (this.addContactForm.customerId == "")
            this.addContactForm.customerId = null;
          if (!this.addContactForm.id) {
            console.log("为啥展示不包括当前记录的公司", this.addContactForm);
            createContactPerson(this.addContactForm)
              .then(() => {
                this.$emit("submited");
                this.$emit("getData");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.$message.error("保存失败");
              });
          } else {
            console.log("为啥展示不包括当前记录的公司", this.addContactForm);
            updateContactPerson(this.addContactForm)
              .then(() => {
                this.$emit("submited");
                this.$emit("handleTableChange", this.$parent.pagination);
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.$message.error("保存失败");
              });
          }
        }
      });
    },
    handleCustomerSearch(value) {
      let maxResultCount = 500;
      let params = {
        maxResultCount: maxResultCount,
      };
      getListCustomer(params).then((res) => {
        this.customersForSelect = res.items;
      });
    },
    whenSelectCustomer(value) {
      this.addContactForm.customerId = value;
      // const customer = this.customersForSelect.find((x) => x.id === value) || {};
      // this.addContactForm.customerId = customer.id || "";
    },
  },
  watch: {
    form: {
      handler() {
        this.addContactForm = this.form;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
