<template>
  <div class="bgStyle wrapper">
    <div class="head">
      <a-space :size="18">
        <a-input
          placeholder="输入名称/联系方式/所属企业/创建时间"
          v-model="contactPersonkey"
          style="width: 300px"
          allowClear
        />
        <a-select
          v-model="enableStatu"
          style="width: 150px"
          ref="select"
          placeholder="选择联系人状态"
          allowClear
        >
          <a-select-option
            v-for="item in arrEnableStatus"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>

        <a-range-picker
          v-model="time"
          style="width: 210px"
          format="YYYY-MM-DD"
        />

        <a-button type="primary" @click="search">查询</a-button>
      </a-space>
      <a-space :size="24">
        <a-button type="primary" @click="addNew">新增联系人</a-button>
        <a-button type="primary" @click="showImportModal">导入联系人</a-button>
      </a-space>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :rowKey="(record) => record.id"
      bordered
      :pagination="pagination"
      @change="handleTableChange"
      size="middle"
    >
      <template slot="creationTime" slot-scope="text">
        {{ text | filterDate }}
      </template>
      <!-- <template slot="enableStatu" slot-scope="text,record">
          {{filterType(record.enableStatus)}}
      </template> -->
      <template slot="operation" slot-scope="text, record">
        <a @click="whenEditRow(record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="whenDisableRow(record)">{{
          record.enableStatus == 1 ? "停用" : "启用"
        }}</a>
        <a-divider type="vertical" />
      </template>
    </a-table>

    <ContactPersonAdd
      ref="add"
      :visible="addCtrl.visible"
      :readonly="addCtrl.readonly"
      :form="this.addCtrl.form"
      :isAdd="this.addCtrl.isAdd"
      :isEdit="this.addCtrl.isEdit"
      :isDetail="this.addCtrl.isDetail"
      @handleTableChange="handleTableChange"
      @getData="getData"
      @submited="
        () => {
          this.addCtrl.visible = false;
        }
      "
      @cancel="
        () => {
          this.addCtrl.visible = false;
        }
      "
    ></ContactPersonAdd>

    <a-modal
      v-model="visibleImport"
      title="导入联系人"
      :width="1200"
      :maskClosable="false"
      :cancel-button-props="{ style: { display: 'none' } }"
      ok-text="开始导入"
      @ok="handleImportOk"
      :confirm-loading="confirmLoading"
    >
      <ImportContactPerson
        ref="refImportContactPerson"
        @uploadContactPersonSuccess="contactPersonSuccess"
      />
    </a-modal>
  </div>
</template>

<script>
import {
  getListContactPerson,
  getAllEnableStatusEnum,
  updateContactPerson,
  importContactPerson,
} from "@/api/customer.js";
import ContactPersonAdd from "./components/AddContactPerson.vue";
import ImportContactPerson from "./components/ImportContactPerson.vue";
const columns = [
  {
    title: "联系人名称",
    dataIndex: "name",
    ellipsis: true,
    align: "center",
  },
  {
    title: "联系方式",
    dataIndex: "contactInfo",
    align: "center",
  },
  {
    title: "所属企业",
    dataIndex: "customerName",
    //width: 280,
    ellipsis: true,
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "enableStatusStr",
    align: "center",
    width: 150,
  },
  {
    title: "创建时间",
    dataIndex: "creationTime",
    scopedSlots: { customRender: "creationTime" },
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
    width: 150,
    align: "center",
  },
];

export default {
  name: "ContactPersonManageIndex",
  components: {
    ContactPersonAdd,
    ImportContactPerson,
  },
  data() {
    return {
      contactPersonkey: "",
      enableStatu: undefined,
      time: [],
      data: [],
      importData: [],
      columns,
      visibleImport: false,
      confirmLoading: false,
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        skipCount: 0,
      },
      addCtrl: {
        form: {},
        visible: false,
        readonly: false,
        isAdd: false,
        isDetail: false,
        isEdit: false,
      },
      id: "",
      arrEnableStatus: [],
      objEnableStatu: {},
    };
  },
  filters: {
    filterDate(val) {
      if (val) {
        let dt = val.substring(0, 19).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
  },
  created() {
    this.getEnableStatu();
  },
  methods: {
    getData() {
      let pageSize = this.pagination.pageSize;
      let skipCount = (this.pagination.current - 1) * pageSize;
      let beginTime =
        this.time[0] && this.time[0].format("YYYY-MM-DD 00:00:00");
      let endTime = this.time[1] && this.time[1].format("YYYY-MM-DD 23:59:59");
      let enableStatus = this.enableStatu;

      let params = {
        key: this.contactPersonkey,
        skipCount,
        maxResultCount: pageSize,
        beginTime,
        endTime,
        enableStatus,
      };

      getListContactPerson(params).then((res) => {
        console.log("getListContactPerson", res);
        this.data = res.items;
        this.pagination.total = res.totalCount;
        this.skipCount = params.skipCount;
      });
    },

    getEnableStatu() {
      getAllEnableStatusEnum().then((res) => {
        this.arrEnableStatus = res || [];
        this.getObjEnableStatu(res);
        this.getData();
        localStorage.setItem(
          "arrEnableStatus",
          JSON.stringify(this.arrEnableStatus)
        );
      });
    },

    getObjEnableStatu(arr) {
      let _this = this;
      arr.forEach((item) => {
        _this.objEnableStatu[item.id] = item.name;
      });
      //console.log("objEnableStatu",this.objEnableStatu);
      localStorage.setItem(
        "objEnableStatu",
        JSON.stringify(this.objEnableStatu)
      );
    },

    /**查找 */
    search() {
      this.pagination.current = 1;
      this.getData();
    },

    /**新增 */
    addNew() {
      this.addCtrl.form = {};
      this.addCtrl.readonly = false;
      this.addCtrl.visible = true;
      this.addCtrl.isAdd = true;
      this.addCtrl.isEdit = false;
      this.addCtrl.isDetail = false;
    },

    /**导入窗口 */
    showImportModal() {
      this.visibleImport = true;
    },

    /**修改 */
    whenEditRow(row) {
      var currentRow = JSON.parse(JSON.stringify(row));
      console.log("拿到的那条数据", currentRow);
      this.addCtrl.form = currentRow;
      this.addCtrl.readonly = false;
      this.addCtrl.visible = true;
      this.addCtrl.isEdit = true;
      this.addCtrl.isAdd = false;
      this.addCtrl.isDetail = false;
    },
    whenDisableRow(row) {
      const _this = this;
      this.$confirm({
        title:
          "确认要" +
          (row.enableStatus == 1 ? "停用" : "启用") +
          "联系人" +
          row.name +
          "吗?",
        onOk() {
          let contactPersonUpdateInputDto = row;
          row.enableStatus == 1
            ? (contactPersonUpdateInputDto.enableStatus = 2)
            : (contactPersonUpdateInputDto.enableStatus = 1);
          updateContactPerson(contactPersonUpdateInputDto)
            .then(() => {
              _this.$message.success("保存成功");
              _this.getData();
            })
            .catch(() => {
              this.$message.error("保存失败");
            });
        },
      });
    },
    /**详情 */
    whenViewRow(row) {
      var entity = JSON.parse(JSON.stringify(row));
      this.addCtrl.form = entity;
      this.addCtrl.readonly = true;
      this.addCtrl.visible = true;
      this.addCtrl.isDetail = true;
      this.addCtrl.isAdd = false;
      this.addCtrl.isEdit = false;
    },

    /**页数更改事件 */
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getData();
    },
    contactPersonSuccess(data) {
      this.importData = data;
    },
    /**联系人导入事件 */
    handleImportOk() {
      let _this = this;
      console.log("导入窗口中的数据", this.importData);
      var contactPersons = _this.importData;
      importContactPerson(contactPersons).then(function (res) {
        _this.$message.success("导入成功!");
        _this.visibleImport = false;
        _this.confirmLoading = false;
        _this.getData();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  background: #fff !important;
}

.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
</style>
