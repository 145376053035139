<template>
  <div class="importContactPerson">
    <h3>选择文件</h3>
    <a-upload-dragger
      name="file"
      :showUploadList="false"
      :before-upload="beforeUpload"
      :file-list="fileList"
      @change="handleChange"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">支持拖拽上传或选择文件进行上传</p>
    </a-upload-dragger>

    <a-row class="info-row">
      <a-col :span="6">
        <h3>导入明细</h3>
      </a-col>
      <a-col :span="12"> </a-col>
      <a-col :span="6" style="text-align: right">
        <a-button type="primary" @click="templateDownload">模板下载</a-button>
        <a-button type="primary" @click="clearClick">清空列表</a-button>
      </a-col>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="importContactPersonData"
      :rowKey="(row, index) => index"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="operation" slot-scope="text, record, index">
        <a-space :size="20">
          <a
            ><a-icon
              type="delete"
              :style="{ fontSize: '26px' }"
              @click="rowClick(index)"
          /></a>
        </a-space>
      </template>
    </a-table>
  </div>
</template>
<script>
const url = process.env.VUE_APP_API_BASE_URL;
import store from "@/store";
import { parseUploadedContactPersonTemp } from "@/api/customer.js";

export default {
  data() {
    return {
      columns: [
        {
          title: "联系人名称",
          dataIndex: "name",
          ellipsis: true,
          align: "center",
        },
        {
          title: "联系方式",
          dataIndex: "contactInfo",
          align: "center",
        },
        {
          title: "所属企业",
          dataIndex: "customerName",
          //width: 280,
          ellipsis: true,
          align: "center",
        },
      ],
      columnheader: [
        {
          title: "序号",
          dataIndex: "order",
          scopedSlots: { customRender: "no" },
        },
        {
          title: "联系人名称",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "联系方式",
          dataIndex: "contactInfo",
          align: "center",
        },
        {
          title: "所属企业",
          dataIndex: "customerName",
          align: "center",
        },
      ],
      importContactPersonData: [],
      SortDefaultValue: "",
      fileList: [],
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
    };
  },
  methods: {
    handleTableChange(pagination) {
      console.log(pagination);
      this.pagination.current = pagination.current; //当前第几页
      this.pagination.pageSize = pagination.pageSize;
      this.SkipCount = (pagination.current - 1) * pagination.pageSize; //从总数据的第几条数据开始取数据
      this.MaxResultCount = pagination.pageSize; //一页多少条
    },
    beforeUpload(file) {
      console.log("file", file);

      this.fileList = [...this.fileList, file];
      return false;
    },
    handleChange(info) {
      console.log("info", info);
      const formData = new FormData();
      formData.append("file", info.file);
      parseUploadedContactPersonTemp(formData).then((res) => {
        console.log("parseUploadedContactPersonTemp", res);
        console.log("columns", this.columns);
        this.columns.splice.apply(this.columns, [
          0,
          this.columns.length,
          ...this.columnheader,
        ]);
        this.columns.push({
          title: "删除",
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        });
        console.log(this.columns);
        this.importContactPersonData = res.map((item, index) => {
          return Object.assign(item, { order: index + 1 });
        });
        this.$emit("uploadContactPersonSuccess", res);
        console.log("res", res);
      });
    },
    rowClick(index) {
      let pageSize = this.pagination.pageSize;
      let current = this.pagination.current - 1;
      let num = current * pageSize + index;
      this.importContactPersonData.splice(num, 1);

      this.fileList.splice(num, 1);

      this.pagination.current = 1;
      this.$emit("uploadContactPersonSuccess", this.importContactPersonData);
    },
    clearClick() {
      console.log("clearClick");
      this.importContactPersonData = [];
      this.$emit("uploadContactPersonSuccess", this.importContactPersonData);
      this.columns.splice.apply(this.columns, [
        0,
        this.columns.length,
        ...this.columnheader,
      ]);
    },
    // 模板下载
    templateDownload() {
      const token = store.getters.getToken;
      const c_c = store.getters.getUserInfo.companyId;
      const __tenant = store.getters.getUserInfo.tenantId;
      const sessionId = store.getters.getSessionId;
      `                  `;
      window.open(
        `${url}/api/sale/contactPerson/getContactPersonExcel?` +
          "&access_token=" +
          token +
          "&__tenant=" +
          __tenant +
          "&c_c=" +
          c_c +
          "&c_s=" +
          sessionId +
          "&negotiateVersion=1"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-upload-text span {
  color: #1890ff;
}

.importsort h3 {
  margin-bottom: 20px;
}

.info-row {
  margin: 20px 0;
}

.info-row .ant-btn {
  margin-right: 15px;
}

.info-row .ant-btn:last-child {
  margin-right: 0;
}

.info-row .ant-col-12:nth-child(3) {
  text-align: right;
}
</style>
